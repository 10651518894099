import React from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { faCity,faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./GoogleMaps.css"
class GoogleMaps extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stores: [{ lat: 42.0642114, lng: 19.5152166, description: "HeadQuarter Blv.Skenderbeu, Nd.115,4001 Shkoder, AL" },
            { lat: 41.3226298, lng: 19.7996629, description: "BlV. Gjergj Fishta P.G&P, Kati Dyte, 1001 Tirana, AL" },
            { lat: 40.4650214, lng: 19.4830526, description: "Rr.Riza Salati, 9401 Vlore, AL" },
            { lat: 41.3175661, lng: 19.4522345, description: "Rr.Lagja Nr 4 Stacioni i Trenit, 2001 Durres, AL" },
            { lat: 40.0749302, lng: 20.1379803, description: "Sheshi Çerçiz Topulli 6001, Gjirokastër, Albania" },
            { lat: 39.874529, lng: 20.0029278, description: "Sarande , AL" },
            { lat: 40.6161113, lng: 20.7760434, description: "Korce, AL" },
            { lat: 40.9393802, lng: 19.7013324, description: "Lushnje, AL" },
            { lat: 41.7800471, lng: 19.643976, description: "Shetitorja Gjergj Fishta 4501, Lezhe, AL" }
            ],
            description: "HeadQuarter  Blv.Skenderbeu, Nd.115,4001  Shkoder, AL",
            classIcon:faCity
        }
    }

    handleToggleOpen = (e) => {
        console.log(e)
        this.setState({
            description: e.title,
            classIcon: e.title.includes("HeadQuarter")?faCity:faBuilding
        });
    }



    displayMarkers = () => {
        return this.state.stores.map((store, index) => {
            return <Marker
                onClick={(e) => this.handleToggleOpen(e)}
                key={index} id={index} position={{
                    lat: store.lat,
                    lng: store.lng
                }}
                title={store.description}
            />
        })
    }



    render() {
        return (
            <React.Fragment>
                <div className="contact-page-map-wrapper google-maps">
                    <Map
                        google={this.props.google}
                        zoom={7}
                        className="mapStyles"
                        initialCenter={{ lat: 41.3349049, lng: 20.0486143 }}
                    >
                        {this.displayMarkers()}
                    </Map>
                    <div className="contact-info-block">
                        <p style={{ whiteSpace: "pre-line" }}>
                            <FontAwesomeIcon icon={this.state.classIcon} style={{padding:"5px",fontSize:"30px"}} />
                            {/* <i className="fa fa-building" style={{padding:"5px"}}></i> */}
                            {/* <br /> */}
                            {this.state.description}
                        </p>
                        <ul className="contact-infos">
                            <li><i className="fa fa-envelope"></i>info@upstaxi.com</li>
                            <li><i className="fa fa-phone-square"></i> (+355) 22249999</li>
                        </ul>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyAoFV_A6GK86i6GR3w3RoKKbsWkgO1ezNo'
})(GoogleMaps);

